import React from "react";

function PageNotFound() {
  return (
    <div
      className="404-page flex j-c-center a-i-center"
      style={{ minHeight: "50vh" }}
    >
      <div className="container">
        <div className="title text-center">
          <div className="big-title text-uppercase">
            We're sorry, this page is not available
          </div>
          <div className="paragraph fs-30">404 - Page not found</div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
