import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authentications } from "../services/services";
import { login } from "../features/userSlice";

// Antd
import { Flex, Form, message } from "antd";

// Components
import CustomInput from "../components/common/CustomInput";

import { useGlobalState } from "../context/GlobalStateProvider";

const formFields = [
  {
    label: "Email",
    name: "email",
    type: "email",
    required: true,
    reqMsg: "Please input your email!",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    required: true,
    reqMsg: "Password is required",
    extraEl: (
      <Link
        className="text-underline p-absolute top-0 right-0"
        to="/forgot-password"
      >
        Forgot your password?
      </Link>
    ),
  },
];

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLoading } = useGlobalState();

  const onFinish = (values) => {
    // props.setLoading(true);
    setLoading(true);
    localStorage.clear();
    authentications(values.email, values.password)
      .then((response) => {
        performLogin(response);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.data?.message || `${error}`);
      });
  };

  const performLogin = (response) => {
    let res = {
      access_token: response.data.meta.access_token, // frappe access token
      byebug_access_token: response.data.data.access_token, // byebug access token
      expires_in: response.data.data.expires_in,
      refresh_token: response.data.data.refresh_token,
    };

    if (res) {
      let data = response.data.data;
      let orgId = data.current_organization_id;
      localStorage.setItem("userid", JSON.stringify(data.id));
      if (data.name) localStorage.setItem("userName", data.name);
      if (orgId) localStorage.setItem("current_organization_id", orgId);
      if (data.role_list)
        localStorage.setItem("access", JSON.stringify(data.role_list));
      if (data.photo_url) localStorage.setItem("userImage", data.photo_url);
      if (data?.organization_name)
        localStorage.setItem("organization_name", data.organization_name);
      if (data?.is_superadmin)
        localStorage.setItem("is_superadmin", data.is_superadmin);
      localStorage.setItem("token", JSON.stringify(res));
      dispatch(
        login({
          username: data.email,
        })
      );

      setTimeout(() => {
        setLoading(false);
        navigate("/account");
      }, 1000);
    }
  };

  return (
    <div className="login-page">
      <div className="container">
        <div className="big-title text-center m-y-50">LOGIN</div>
        <Form
          layout="vertical"
          name="login"
          style={{
            maxWidth: 600,
            margin: "0 auto",
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {formFields.map((el, i) => (
            <Fragment key={`login-form-field-${i}`}>
              {el?.extraEl && (
                <Form.Item className="p-relative">
                  <CustomInput item={el} />
                  {el.extraEl}
                </Form.Item>
              )}
              {!el?.extraEl && <CustomInput item={el} />}
            </Fragment>
          ))}

          <Form.Item>
            <Flex justify="space-between" align="center">
              <Link className="text-red fs-18 text-underline" to="/register">
                Create account
              </Link>
              <button className="btn btn-primary btn-large" type="submit">
                Submit
              </button>
            </Flex>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;
