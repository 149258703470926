import React from "react";

import { Empty } from "antd";

function CommunityBlog() {
  return (
    <div
      className="communityBlogPage flex j-c-center a-i-center"
      style={{ minHeight: "50vh" }}
    >
      <Empty />
    </div>
  );
}

export default CommunityBlog;
