import React from "react";
import { Outlet } from "react-router-dom";

import Announcement from "../components/Announcement";
// import Offer from "../components/Offer";
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";

// Antd
import { FloatButton } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

function MainLayout({
  isScrollingUp,
  openCart,
  setOpenCart,
  showTopBtn,
  backToTop,
}) {
  return (
    <div className="main-page">
      <Announcement />
      {/* <Offer /> */}
      <Topbar
        isScrollingUp={isScrollingUp}
        openCart={openCart}
        setOpenCart={setOpenCart}
      />
      <div className="main-content">
        <Outlet />
      </div>

      <Footer />

      {showTopBtn && (
        <FloatButton
          icon={<ArrowUpOutlined />}
          shape="square"
          onClick={backToTop}
        />
      )}
    </div>
  );
}

export default MainLayout;
