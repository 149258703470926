import React from "react";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Scoped CSS
import styles from "./Announcement.module.scss";

const announcementList = [
  { title: "Transform Inside Out", specialStyle: true },
];

function Announcement() {
  return (
    <div className={`${styles.announcementBar} text-center p-y-10`}>
      <div className="container">
        <Swiper
          modules={[Navigation, Autoplay]}
          navigation={true}
          loop={announcementList?.length > 1}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          className="mySwiper"
        >
          {announcementList.map((el, i) => (
            <SwiperSlide key={`ann-${i}`}>
              <div
                className={`paragraph ${
                  el.specialStyle
                    ? "font-bold letterSpace-5 fw-500 text-uppercase"
                    : ""
                }`}
              >
                {el?.title}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Announcement;
