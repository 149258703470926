import React, { Fragment, useEffect, useState } from "react";

// Antd
import { Form, Modal, message, Spin, Row, Col } from "antd";

// Components
import CustomInput from "../common/CustomInput";

// API
import { addAddress, updateAddress } from "../../ducks/services";

function AddressModal({
  isModalOpen,
  handleOk,
  handleCancel,
  countryDropdown,
  statesDropdown,
  mode,
  addressId,
  userAddresses,
  reloadApi,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const formFields = [
    {
      // label: "Country/Region",
      name: "country",
      type: "select",
      required: true,
      reqMsg: "Please select Country/Region!",
      options: countryDropdown,
      placeholder: "Country/Region",
    },
    {
      name: "first_name",
      type: "text",
      required: true,
      placeholder: "First Name",
    },
    {
      name: "last_name",
      type: "text",
      required: true,
      reqMsg: "Please insert Last Name!",
      placeholder: "Last Name",
    },
    {
      name: "address",
      type: "text",
      required: true,
      reqMsg: "Please insert Address!",
      placeholder: "Address",
    },
    {
      name: "address_2",
      type: "text",
      required: false,
      placeholder: "Apartment, suite, etc. (Optional)",
    },
    {
      name: "postcode",
      type: "text",
      required: true,
      reqMsg: "Please insert Postcode!",
      placeholder: "Postcode",
    },
    {
      name: "city",
      type: "text",
      required: true,
      reqMsg: "Please insert City!",
      placeholder: "City",
    },
    {
      // label: "Country/Region",
      name: "state",
      type: "select",
      required: true,
      reqMsg: "Please select State/territory!",
      options: statesDropdown,
      placeholder: "State/territory",
    },
    {
      name: "default_shipping_address",
      type: "checkbox",
      required: false,
      title: "Set as default address",
    },
  ];

  useEffect(() => {
    // Example: Set initial form values
    if (mode === "edit" && addressId && userAddresses?.list) {
      const formValues = userAddresses?.list?.find((el) => el.id === addressId);
      form.setFieldsValue({
        ...formValues,
        default_shipping_address: formValues?.default_shipping_address === "1",
      });
    }
  }, [mode, addressId]);

  const onFinish = async (val) => {
    setLoading(true);

    const payload = {
      ...val,
      default_shipping_address: val?.default_shipping_address ? "1" : "0",
    };

    if (mode === "edit") payload.address_id = addressId;

    await (mode === "add" ? addAddress : updateAddress)(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          message.success(
            `Address ${mode === "add" ? "added" : "updated"} Successfully!`
          );
          handleOk();
          reloadApi();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title={`${mode === "add" ? "Add" : "Edit"} Address`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Spin size="large" spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="addressForm"
          id="addressForm"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            default_shipping_address: false,
          }}
        >
          {formFields.map((el, i) => (
            <Fragment key={`address-input-${i}`}>
              <CustomInput item={el} />
            </Fragment>
          ))}

          <Row>
            <Col span={24} className="text-right">
              <button
                type="button"
                className="btn btn-sub btn-large m-r-20"
                onClick={handleCancel}
              >
                Close
              </button>
              <button
                className="btn btn-primary btn-large"
                type="submit"
                form="addressForm"
              >
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}

export default AddressModal;
