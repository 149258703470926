import React from "react";
import { Link } from "react-router-dom";

// Antd
import { Breadcrumb } from "antd";

// Images
import bannerImg from "../assets/images/returnPolicy.png";

// Breadcrumb Data
const breadcrumbItems = [
  { title: <Link to="/">Home</Link> },
  { title: <div>Refund Policy</div> },
];

function ReturnPolicy() {
  return (
    <div className="condition-page">
      <div className="container">
        <div className="breadcrumbContainer m-y-30">
          <Breadcrumb separator=">" items={breadcrumbItems} />
        </div>

        <div className="big-title text-uppercase text-center m-b-50">
          Refund Policy
        </div>

        <div className="body m-b-50">
          <div className="font-bold m-b-20">
            We want you to be happy and excited with our products. We are 100%
            confident we have a great product that improve lives and we back it
            with a no-nonsense money-back guarantee.
          </div>
          <img className="m-b-20" src={bannerImg} alt="jogging" />
          <div className="paragraph">
            <div className="m-b-30">
              A satisfied customer is worth value in gold! If you are not
              satisfied with your purchase and are unhappy with your order, we
              want to absolutely make it right!{" "}
              <Link className="text-red" to="/contact">
                Contact us
              </Link>{" "}
              within 30 days of your purchase and we will work to arrange a full
              refund or credit your account with a Monolith Nutrition e-Gift
              card equal to the value of your product.
            </div>

            <div>
              Our products and refund policy are subject to our{" "}
              <Link className="text-red" to="/condition-of-sale">
                Conditions of Sale
              </Link>
              . Clearance items are final sale and may not be returned nor
              eligible for price adjustments. We welcome you to reach out to us
              if you have any questions or need further clarification!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReturnPolicy;
