import * as action_types from "./constants";

const initialState = {
  reviewsList: [],
  userData: null,
  optionList: [],
  orderHistory: [],
  userAddresses: [],
  salesOrder: null,
  salesOrderMeta: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.REVIEWS_LIST:
      return { ...state, reviewsList: data };
    case action_types.USER_PROFILE:
      return { ...state, userData: data };
    case action_types.OPTION_LIST:
      return { ...state, optionList: data };
    case action_types.ORDER_HISTORY:
      return { ...state, orderHistory: data };
    case action_types.USER_ADDRESSES:
      return { ...state, userAddresses: data };
    case action_types.SALES_ORDER:
      return { ...state, salesOrder: data, salesOrderMeta: meta };
    default:
      return state;
  }
};
