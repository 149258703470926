import React from "react";

// Images
import paymentOptionsImg from "../../assets/images/payment-options-01.png";
import malaysiaIcon from "../../assets/images/msia-icon.png";

// Icons
import { TruckOutlined, FieldTimeOutlined } from "@ant-design/icons";

function ProductShippingInfo() {
  return (
    <div className="productShippingInfo">
      <div className="countryPaymentContainer">
        <div className="countryInfo">
          <img src={malaysiaIcon} alt="Malaysia" />
          <div className="font-bold text-uppercase">Malaysia</div>
        </div>
        <img src={paymentOptionsImg} alt="payment options" />
      </div>
      {/* <div className="flex gap-10 a-i-center text-uppercase">
        <TruckOutlined className="fs-44" />
        <div className="font-bold">FREE shipping for orders over MYR200</div>
      </div> */}
      <div className="flex gap-10 a-i-center">
        <FieldTimeOutlined className="fs-44" />
        <div>
          <div className="font-bold text-uppercase">
            WM Delivery: 5 - 7 Working Days
          </div>
          <div className="paragraph m-l-20">
            EM Delivery: 5 - 7 Working Days
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductShippingInfo;
