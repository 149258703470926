import { combineReducers } from "redux";
import userReducer from "../../features/userSlice";
import global from "../../ducks/reducers";
import { DESTROY_SESSION } from "../../ducks/constants";

const appReducer = combineReducers({
  user: userReducer,
  global,
});

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
