import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/userSlice";
import { onClear } from "../ducks/actions";

// Components
import MyOrders from "../components/Account/MyOrders";
import MyAddresses from "../components/Account/MyAddresses";

// Antd
import { Tabs } from "antd";

function Account(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.global.userData);

  const tabList = [
    {
      key: "1",
      label: <div className="tabItem">My Orders</div>,
      children: <MyOrders {...props} />,
    },
    {
      key: "2",
      label: <div className="tabItem">My Addresses</div>,
      children: <MyAddresses {...props} />,
    },
  ];

  const logoutHandler = () => {
    dispatch(logout());
    dispatch(onClear());
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="account-page">
        <div className="container">
          <div className="titleContent">
            <div className="big-title text-center text-uppercase">
              My Account {""}
              <span
                className="fs-16"
                style={{
                  textTransform: "initial!important",
                  fontWeight: "normal",
                  verticalAlign: "middle",
                }}
              >
                {userData?.email}
              </span>
            </div>

            <div className="welcomeContent">
              <div>Welcome back! {userData?.first_name}</div>
              <button
                className="btn btn-primary btn-large"
                onClick={logoutHandler}
              >
                Logout
              </button>
            </div>
          </div>

          <Tabs defaultActiveKey="1" items={tabList} />
        </div>
      </div>
    </>
  );
}

export default Account;
