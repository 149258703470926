import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";

// Constants
import { productList } from "../configs/constantData";

// API
import { getReviewsList } from "../ducks/actions";
import { addToCart } from "../ducks/services";
import { getUserProfile } from "../ducks/actions";

// Context
import { useGlobalState } from "../context/GlobalStateProvider";

// Antd
import { Breadcrumb, Drawer, Rate, message, Spin } from "antd";

// Icon
import { ZoomInOutlined } from "@ant-design/icons";

// Components
import ProductShippingInfo from "../components/ProductItem/ProductShippingInfo";
import CustomAccordion from "../components/ProductItem/CustomAccordion";
import QtyEditor from "../components/common/QtyEditor";
import CartDrawer from "../components/CartDrawer";

function ProductItem({ openCart, setOpenCart }) {
  const [currentImg, setCurrentImg] = useState(1);
  const [qty, setQty] = useState(1);
  const [curOpen, setCurOpen] = useState(null);
  const paramName = useParams().name;
  const { isMobile, setLoading, isLoggedIn } = useGlobalState();
  const navigate = useNavigate();
  const [openZoomDrawer, setOpenZoomDrawer] = useState(false);
  const userData = useSelector((state) => state.global.userData);
  const cartAmount = userData?.cart?.amount;

  const selectedProduct = productList?.[paramName];
  const existingCartQty = userData?.cart?.cart_items?.find(
    (el) => el?.product?.id === selectedProduct?.id
  )?.qty;

  const validPath = ["essentials", "leanessentials"];

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getReviewsList());
  // }, []);

  useEffect(() => {
    if (!validPath.includes(paramName)) {
      navigate("/page-not-found", { replace: true });
      return;
    }
    setCurrentImg(1);
    setQty(1);
    setCurOpen(null);
  }, [paramName]);

  const minusQty = () => {
    if (qty === 1) return;
    setQty((quantity) => quantity - 1);
  };

  const changeQty = (val) => {
    if (val.length === 1 && val === "0") {
      setQty("");
    } else {
      setQty(val);
    }
  };

  const addQty = () => {
    setQty((quantity) => quantity + 1);
  };

  const handleOpenZoomDrawer = () => {
    setOpenZoomDrawer(true);
  };

  const handleCloseZoomDrawer = () => {
    setOpenZoomDrawer(false);
  };

  const handleAddToCart = async () => {
    if (!isLoggedIn()) {
      message.error("Please login to continue.");
      navigate("/login");
      return;
    }

    let productQty = 1;
    if(Number(qty) > 0) {
      productQty = Number(qty)
    } else {
      setQty(1)
    }

    if(productQty * selectedProduct.price > 3000 || Number(cartAmount) + (productQty * selectedProduct.price) > 3000) {
      message.error("Exceed maximum RM3000 per transaction. Please reduce your quantity.")
      return
    }

    setLoading(true);

    const payload = {
      product_id: selectedProduct?.id,
      quantity: productQty,
      to_add: "1",
    };

    await addToCart(payload, userData?.cart?.amount)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          dispatch(getUserProfile());
          setOpenCart(true);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseCart = () => {
    setOpenCart(false);
    setQty(1);
  };

  return (
    <div className="product-page">
      <div className="container">
        <div className="breadcrumbContainer m-y-30">
          <Breadcrumb separator=">" items={selectedProduct?.breadcrumbName} />
        </div>

        <div className="productBrief">
          {!isMobile && (
            <div className="productImagesContainer p-relative">
              <div className="imgPreviewList">
                {selectedProduct?.productImages.map((el, i) => (
                  <div
                    className={`previewImgBox ${
                      currentImg - 1 === i ? "active" : ""
                    }`}
                    key={`mct-img-${i}`}
                    onClick={() => setCurrentImg(i + 1)}
                  >
                    <img src={el} alt={`mct-${i}`} />
                  </div>
                ))}
              </div>
              <div className="displayImg">
                <img
                  src={selectedProduct?.productImages[currentImg - 1]}
                  alt="mct"
                />
              </div>
            </div>
          )}

          {isMobile && (
            <Swiper
              modules={[Navigation, Pagination]}
              navigation={true}
              pagination={{
                type: "fraction",
              }}
              // onSwiper={setSwiperBanner}
              className="mySwiper productImgsSwiper commonMobSwiper"
            >
              {selectedProduct?.productImages.map((el, i) => (
                <SwiperSlide key={`product-item-img-${i}`}>
                  <div className="productSwiperImgWrapper">
                    <img className="displayImg" src={el} alt="banner" />
                  </div>
                </SwiperSlide>
              ))}
              <ZoomInOutlined
                className="zoomIcon"
                onClick={handleOpenZoomDrawer}
              />
            </Swiper>
          )}

          <div className="productInfoContainer">
            <div className="titleContent m-b-30">
              {selectedProduct?.productName}
              {/* <Rate disabled defaultValue={5} className="m-b-20 text-red" /> */}
              <div className="big-title fs-30 fw-500">
                RM{selectedProduct?.price.toFixed(2)}
              </div>
            </div>
            <div className="productInfoDesc paragraph">
              <div className="font-bold">
                {selectedProduct?.productDescHead}
              </div>
              <div>{selectedProduct?.productDescBody}</div>
            </div>
            <div className="productCustomize flex flex-col gap-20 m-b-30">
              <div className="flex a-i-center gap-10 font-bold">
                <div>Package Size</div>
                <div className="customoizeOption">{selectedProduct?.size}</div>
              </div>

              <div className="flex a-i-center gap-10 font-bold">
                <div>Flavour</div>
                <div className="customoizeOption">
                  {selectedProduct?.flavour}
                </div>
              </div>
            </div>

            <div className="productCta flex gap-10 m-b-30">
              <QtyEditor
                qty={qty}
                minusQty={minusQty}
                addQty={addQty}
                changeQty={changeQty}
                min={1}
              />
              <button
                className="btn btn-primary btn-large text-uppercase"
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>
            </div>

            <ProductShippingInfo />
          </div>
        </div>

        {/* <div className="productReviews">
          <div className="fw-300 fs-25 text-uppercase m-b-20">
            Customer Reviews
          </div>
          <div className="flex j-c-sb a-i-center">
            <div className="flex gap-20 a-i-center">
              <Rating defaultValue={4.7} precision={0.5} readOnly />
            </div>
          </div>
        </div> */}

        <div className="productFeature">
          <div className="big-title text-uppercase text-center m-b-50">
            Big Things Come In These Small Packages!
          </div>

          <div className="productFtImgSec">
            {/* {isMobile && (
              <Swiper
                modules={[Navigation]}
                navigation={true}
                className="mySwiper commonMobSwiper"
              >
                {selectedProduct?.productFeatureImages.map((el, i) => (
                  <SwiperSlide key={`mobile-prod-ft-${i}`}>
                    <div
                      className="ftImgWrapper"
                      style={
                        el?.needBorder
                          ? { border: "1px solid var(--border-grey)" }
                          : {}
                      }
                    >
                      <img src={el.img} alt={el.alt} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )} */}

            {/* {!isMobile && ( */}
            <div className="gridContent">
              {selectedProduct?.productFeatureImages.map((el, i) => (
                <div
                  className="gridItem"
                  key={`product feature ${i}`}
                  style={
                    el?.needBorder
                      ? { border: "1px solid var(--border-grey)" }
                      : {}
                  }
                >
                  <img src={el.img} alt={el.alt} />
                </div>
              ))}
            </div>
            {/* )} */}
          </div>

          <div className="accordionContent m-b-50">
            {selectedProduct?.accordionData.map((el, i) => (
              <CustomAccordion
                data={el}
                num={i + 1}
                curOpen={curOpen}
                onCurOpen={setCurOpen}
                key={`${el.title}-${i}`}
              />
            ))}
          </div>
        </div>
      </div>

      <ZoomImgDrawer
        onOpenZoomDrawer={openZoomDrawer}
        onHandleCloseZoomDrawer={handleCloseZoomDrawer}
        selectedProduct={selectedProduct}
      />
      <CartDrawer onOpenCart={openCart} onHandleCloseCart={handleCloseCart} />
    </div>
  );
}

function ZoomImgDrawer({
  onOpenZoomDrawer,
  onHandleCloseZoomDrawer,
  selectedProduct,
}) {
  return (
    <Drawer
      className="zoomImgDrawer custom-drawer"
      onClose={onHandleCloseZoomDrawer}
      open={onOpenZoomDrawer}
      placement="bottom"
      height="100%"
    >
      <div className="zoomImgContainer">
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={true}
          pagination={{
            type: "fraction",
          }}
          className="mySwiper zoomImgsSwiper commonMobSwiper"
        >
          {selectedProduct?.productImages.map((el, i) => (
            <SwiperSlide key={`zoom-item-img-${i}`}>
              <div className="zoomSwiperImgWrapper">
                <img className="displayImg" src={el} alt="banner" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Drawer>
  );
}

export default ProductItem;
