import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Context
import { useGlobalState } from "../../context/GlobalStateProvider";

// APIs
import { addToCart } from "../../ducks/services";
import { getUserProfile } from "../../ducks/actions";

// Antd
import { Drawer } from "antd";

// Images
// import essentialBoxImg from "../../assets/images/box_MCT Essentials_01.png";
// import leanEssentialBoxImg from "../../assets/images/box_MCT LeanEssentials_02.png";

// import { essentialProductId, leanProductId } from "../../configs/constants";
// import { productList } from "../../configs/constantData";
import CartItem from "./components/CartItem";

function CartDrawer({ onOpenCart, onHandleCloseCart }) {
  const { isMobile, loading, setLoading } = useGlobalState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reloadApi = () => {
    dispatch(getUserProfile());
  };

  const freeShippingAmt = 200;

  const userData = useSelector((state) => state.global.userData);
  const cartData = userData?.cart;
  const cartAmount = userData?.cart?.amount;

  const cartQty = userData?.cart?.cart_items?.reduce(
    (acc, cur) => acc + cur.qty,
    0
  );

  const freeShippingProgressWidth =
    cartData?.amount >= freeShippingAmt
      ? "100"
      : `${(cartData?.amount / freeShippingAmt) * 100}`;

  const navigateToProducts = () => {
    onHandleCloseCart();
    navigate("/products");
  };

  return (
    <Drawer
      className="cartDrawer custom-drawer"
      title={`Cart ${cartQty > 0 ? `(${cartQty})` : ""}`}
      onClose={onHandleCloseCart}
      open={onOpenCart}
      width={isMobile ? "100%" : "480px"}
      footer={
        cartQty > 0 ? (
          <div className="cartFooter">
            <div className="flex j-c-sb text-uppercase font-bold fs-20 p-t-12 p-b-20">
              <div>Total</div>
              <div className="text-red">RM{cartData?.amount}</div>
            </div>

            {/* <div className="grid grid-2-col gap-10 m-b-10"> */}
            {/* <button className="btn btn-primary btn-large">View Cart</button> */}
            <Link
              to="/checkout"
              className="btn btn-primary btn-large full-width m-b-15"
            >
              Check Out
            </Link>
            {/* </div> */}

            <div className="text-center m-b-20">
              Taxes and shipping calculated at checkout
            </div>
          </div>
        ) : (
          false
        )
      }
    >
      {/* Empty Cart */}
      {!cartQty && (
        <div className="cartContainer emptyCartContainer">
          <div className="cartContent text-center">
            <div className="fs-20 font-bold m-b-30">Your Cart is Empty</div>
            <div
              className="btn btn-primary btn-large fs-18"
              onClick={navigateToProducts}
            >
              Continue Shopping
            </div>
          </div>
        </div>
      )}

      {cartQty > 0 && (
        <div className="cartContainer hasCartContainer">
          <div className="cartContent">
            {/* <div className="freeShippingContainer flex p-b-24 a-i-end gap-10">
              <div className="flex flex-col full-width gap-5">
                <div className="paragraph">
                  {cartData?.amount >= freeShippingAmt &&
                    "Your order qualifies for free shipping"}
                  {cartData?.amount < freeShippingAmt && (
                    <span className="fs-12">
                      You're{" "}
                      <span className="fw-550">
                        RM{(freeShippingAmt - cartData?.amount).toFixed(2)}
                      </span>{" "}
                      away from free shipping
                    </span>
                  )}
                </div>
                <div className="progressBarContainer">
                  <div className="progressBarInactive"></div>
                  <div
                    className="progressBarActive"
                    style={{ width: `${freeShippingProgressWidth}%` }}
                  ></div>
                </div>
              </div>
              <div className="font-bold">RM{freeShippingAmt.toFixed(2)}</div>
            </div> */}

            <div className="cartItemsContainer">
              {cartData?.cart_items?.map((el, i) => (
                <CartItem
                  item={el}
                  reloadApi={reloadApi}
                  addToCart={addToCart}
                  key={`cart-item-${i}`}
                  loading={loading}
                  setLoading={setLoading}
                  cartAmount={cartAmount}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default CartDrawer;
