import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useGlobalState } from "../context/GlobalStateProvider";

import { resetPassword } from "../ducks/services";

// Antd
import { Flex, Form, Input, message } from "antd";

function ResetPassword() {
  const [form] = Form.useForm();
  const { setLoading } = useGlobalState();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const token = queryParameters.get("token");
  const navigate = useNavigate();

  const onFinish = async (val) => {
    // return;
    setLoading(true);
    let payload = {
      new_password: val?.confirm_password,
      reset_password_token: token,
    };

    await resetPassword(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          message.success(res.data.message);
          navigate("/login", { replace: true });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="forgot-pw-page">
      <div className="container">
        <div className="big-title text-center m-y-50">RESET PASSWORD</div>
        <Form
          form={form}
          layout="vertical"
          name="resetPw"
          style={{
            maxWidth: 600,
            margin: "0 auto",
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={"New Password"}
            name={"new_password"}
            rules={[
              {
                required: true,
                message: "Please enter new password",
              },
            ]}
          >
            <Input.Password placeholder={"Please enter password"} />
          </Form.Item>

          <Form.Item
            label={"Confirm Password"}
            name={"confirm_password"}
            dependencies={["new_password"]}
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The Password does not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder={"Please enter confirm password"} />
          </Form.Item>

          <Form.Item>
            <Flex justify="space-between" align="center">
              <Link className="active fs-18 text-underline" to="/login">
                Back to login
              </Link>
              <button className="btn btn-primary btn-large" type="submit">
                Send
              </button>
            </Flex>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
