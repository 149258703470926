import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";

import { removeFromCart } from "../../../ducks/services";

import { message, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import QtyEditor from "../../common/QtyEditor";
import { getUserProfile } from "../../../ducks/actions";

const CartItem = ({
  item,
  reloadApi,
  addToCart,
  loading,
  setLoading,
  cartAmount,
}) => {
  const cartItemId = item?.id;
  const itemQty = item?.qty;
  const productData = item?.product;
  const [cartItemQty, setCartItemQty] = useState(itemQty);
  const dispatch = useDispatch();
  const [debouncedText] = useDebounce(cartItemQty, 500);

  useEffect(() => {
    setCartItemQty(itemQty);
  }, [itemQty]);

  const handleDeleteCart = async (id) => {
    setLoading(true);

    const payload = {
      cart_item_id: id,
    };

    await removeFromCart(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          reloadApi();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModifyCart = useCallback(
    async (val) => {
      // if(Number(cartAmount) + (Number(cartItemQty) * 198) > 3000) {
      //   message.error("Exceed maximum RM3000 per transaction. Please reduce your quantity.")
      //   return
      // }

      const payload = {
        product_id: productData?.id,
        quantity: val,
      };

      setLoading(true);

      await addToCart(payload)
        .then((res) => {
          // props.setLoading(false);
          if (res.data.code === 200) {
            dispatch(getUserProfile());
            // setOpenCart(true);
          } else {
            message.error(res.data.message);
          }
        })
        .catch((e) => {
          message.error(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addToCart, dispatch, productData?.id, setLoading]
  );

  useEffect(() => {
    if (debouncedText === "") return;
    if (Number(debouncedText) === 0) return;

    if (
      debouncedText !== 0 &&
      (debouncedText < itemQty || debouncedText > itemQty)
    ) {
      handleModifyCart(debouncedText);
    }
  }, [debouncedText, handleModifyCart]);

  const minusQty = () => {
    if (cartItemQty === 1) return;
    setCartItemQty((quantity) => quantity - 1);
  };

  const changeQty = (val) => {
    if (val.length === 1 && val === "0") {
      setCartItemQty("1");
    } else {
      setCartItemQty(val);
    }
  };

  const addQty = () => {
    setCartItemQty((quantity) => quantity + 1);
  };

  return (
    <div className="cartItem flex j-c-sb m-t-20">
      <div className="productDetails flex gap-20 a-i-start">
        <img
          className="productImg"
          src={productData?.featured_graphic_url}
          alt={productData?.name}
        />

        <div className="productInfo">
          <div className="productDesc m-b-30">
            <div className="title font-bold m-b-10 text-uppercase">
              {productData?.name}
            </div>
            <div className="info">{productData?.description}</div>
          </div>

          <div className="productCta flex gap-10">
            <QtyEditor
              qty={cartItemQty}
              minusQty={minusQty}
              addQty={addQty}
              changeQty={changeQty}
              min={0}
            />
            <div
              className="deleteBtn text-red fs-18"
              onClick={() => handleDeleteCart(item?.id)}
            >
              <DeleteOutlined />
            </div>
          </div>
        </div>
      </div>

      <div className="totalPrice font-bold text-red">RM{item?.amount}</div>

      <Spin size="large" spinning={loading} fullscreen={true} />
    </div>
  );
};

export default CartItem;
