import React from "react";

// Scoped CSS
import styles from "../../pages/Home.module.scss";

function ProductIngredientBox({ product }) {
  return (
    <div className="productIngredientBox">
      <img
        style={product?.imgSize && { objectFit: product?.imgSize }}
        src={product.image}
        alt={product.alt}
      />
      <div className="text-uppercase fs-20 m-b-15 text-red text-center">
        {product.title}
      </div>
      <div className={styles.desc}>{product.desc}</div>
    </div>
  );
}

export default ProductIngredientBox;
