export const baseUrl = process.env.REACT_APP_BASE_URL;
export const loginID = process.env.APP_CLIENT_ID;
export const apiMethod = baseUrl + "/api/method";
export const apiresource = baseUrl + "/api/resource";
export const externalBaseUrl = process.env.REACT_APP_EXTERNAL_URL;
export const corsUrl = process.env.CORS_URL;
export const googleClientId = process.env.GOOGLE_CLIENT_ID;

export const essentialProductId = process.env.ESSENTIAL_PRODUCT_ID;
export const leanProductId = process.env.LEAN_PRODUCT_ID;
export const proProductId = process.env.PRO_PRODUCT_ID;
