import React, { Fragment, useEffect, useRef } from "react";

import { NavLink } from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";

import styles from "../Topbar.module.scss";

function TopbarMenu({
  element,
  onOpenMenu,
  onHandleOpenMenu,
  onOpenSubMenu,
  onHandleOpenSubMenu,
  onSetOpenSubMenu,
}) {
  const topbarMenuRef = useRef(null);

  useEffect(() => {
    const handleOpenTopbarMenu = (e) => {
      if (!e.target.closest(`.${topbarMenuRef?.current?.className}`)) {
        onHandleOpenMenu(null);
        onSetOpenSubMenu(false);
      }
    };

    document.addEventListener("click", handleOpenTopbarMenu);

    return () => {
      document.removeEventListener("click", handleOpenTopbarMenu);
    };
  }, [topbarMenuRef, onHandleOpenMenu, onSetOpenSubMenu]);

  return (
    <div
      className={styles.topbarMenuItem}
      onClick={() => onHandleOpenMenu(element.value)}
      ref={topbarMenuRef}
    >
      <div
        className={`${styles.menuTitle} ${
          onOpenMenu === element.value ? styles.active : ""
        }`}
      >
        {element.title}
        <DownOutlined className="arrowIcon" />
      </div>
      <div
        className={`${styles.menuChild} ${
          onOpenMenu !== element.value ? "d-none" : ""
        }`}
      >
        {element.children.map((item, index) => (
          <Fragment key={`menuChild-${index}`}>
            {!item?.grandchildren && (
              <NavLink to={item.link}>{item.name}</NavLink>
            )}

            {item?.grandchildren && item?.grandchildren?.length !== 0 && (
              <>
                <div
                  className={`${onOpenSubMenu ? styles.active : ""} ${
                    styles.grandChildTitle
                  }`}
                  onClick={(e) => onHandleOpenSubMenu(e)}
                >
                  {item.name}
                </div>
                <ul className={`${onOpenSubMenu ? "" : "d-none"} p-l-45`}>
                  {item?.grandchildren?.map((gItem, gIndex) => (
                    <Fragment key={`menuGrandChild-${gIndex}`}>
                      <NavLink to={gItem.link}>{gItem.name}</NavLink>
                    </Fragment>
                  ))}
                </ul>
              </>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default TopbarMenu;
