import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getUserProfile } from "../ducks/actions";

import { useGlobalState } from "../context/GlobalStateProvider";

import { Spin } from "antd";

export default function UserDataInfo() {
  const { loading, isLoggedIn } = useGlobalState();
  // const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(getUserProfile());
    }
  }, [isLoggedIn()]);

  return <Spin size="large" spinning={loading} fullscreen={true} />;
}
