import React from "react";
import { Link } from "react-router-dom";
import { ShoppingOutlined } from "@ant-design/icons";

function CheckoutTopbar() {
  return (
    <div className="border-bottom-1 border-grey m-b-30">
      <div className="container">
        <div className="flex j-c-sb a-i-center p-y-15">
          <Link to="/" className="fs-20 fw-500">
            Monolith Nutrition
          </Link>
          <Link to="/products">
            <ShoppingOutlined />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CheckoutTopbar;
