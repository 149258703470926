import React from "react";
import { Link } from "react-router-dom";

import styles from "./ImgTxtTwoCol.module.scss";

import { useGlobalState } from "../../../context/GlobalStateProvider";

function ImgTxtTwoCol({ data }) {
  const { isMobile } = useGlobalState();

  const flexOrderTxt = isMobile
    ? { order: 1, textAlign: "left" }
    : { order: 2, textAlign: "right" };
  const flexOrderImg = isMobile ? { order: 2 } : { order: 1 };
  return (
    <div className={styles.row}>
      {/* {data.imgDirection === "right" && ( */}
      <>
        <div
          className={styles.colTxt}
          style={data?.imgDirection === "left" ? flexOrderTxt : {}}
        >
          <div className={styles.title}>{data.title}</div>
          <div className={styles.body}>
            <div className="paragraph">{data.body}</div>
            {data?.button && (
              <Link to={data.button.link} className="btn btn-primary btn-large">
                {data.button.label}
              </Link>
            )}
          </div>
        </div>
        <div
          className={styles.colImg}
          style={data?.imgDirection === "left" ? flexOrderImg : {}}
        >
          <img
            className={data.imgSpaceDirection}
            src={data.image}
            alt={data.title}
          />
        </div>
      </>
      {/* )} */}

      {/* {data.imgDirection === "left" && (
        <>
          <div className={styles.colImg}>
            <img
              className={data.imgSpaceDirection}
              src={data.image}
              alt={data.title}
            />
          </div>
          <div className={`${styles.colTxt} text-left`}>
            <div className={styles.title}>{data.title}</div>
            <div className={styles.body}>
              <div className="paragraph">{data.body}</div>
              {data?.button && (
                <Link
                  to={data.button.link}
                  className="btn btn-primary btn-large"
                >
                  {data.button.label}
                </Link>
              )}
            </div>
          </div>
        </>
      )} */}
    </div>
  );
}

export default ImgTxtTwoCol;
