import React from "react";

function Faq() {
  return (
    <div className="faq-page">
      <div className="container">
        <div className="big-title text-center">FAQ</div>
      </div>
    </div>
  );
}

export default Faq;
