import React from "react";
import { Link } from "react-router-dom";

// Components
import ContentDivider from "../components/common/ContentDivider";

// Images
import bannerImg from "../assets/images/absolute_banner.png";
import coconutBanner from "../assets/images/coconut_bg.png";
import coconutCutImg from "../assets/images/coconut-cut-01.png";

const coconutDetails = [
  {
    name: "BENEFITS ABSOLUTELY ABUNDANT",
    desc: "MCT has so much positive benefits that you would be short-changing yourself for not knowing about and implementing it within your health and wellness regimen. Easily incorporated into the majority of lifestyles and diets, MCT is acknowledged in science and medicine across studies and research to have great physiological and functional characteristics which help to treat various health disorders and improve health1.",
    subtitle:
      "Positively impacts gut health and digestion, weight management, cognitive health.",
  },
  {
    name: "A CORNERSTONE SUPPLEMENT TO AID KETOSIS",
    desc: "MCT is especially well-known to individuals who commit to ketogenic and low-carb diets and lifestyles, sport athletes, nutritionists, pharmacists, and doctors. This powerful fat is leveraged upon to aid in the natural inducing of ketosis within the body which has a host of benefits.",
    subtitle:
      "Leverage on MCT’s unique properties to help induce a state of mild ketosis for amazing health and wellness.",
  },
  {
    name: "NOTORIOUSLY HARD TO GET RIGHT IN POWDER FORM – BUT NOT FOR US!",
    desc: "As much as how MCT is great to improve health and wellness, MCT oils are well-known to be difficult to ingest, unappetizing, and difficult to digest for the uninitiated who have not been consistently consumed and adapted to the oil’s characteristics. Monolith Nutrition takes pride in having the scientific knowledge and acumen behind the scenes in the lab, coupled with the capabilities and experience in quality manufacturing standards – resulting in the an innovatively tantalizing experience that make MCT consumption an absolute joy.",
    subtitle:
      "None of the digestive issues associated with oil-based MCT products – you won’t believe the great taste and ease of mixability!",
  },
];

function AbsolutelyMct() {
  return (
    <div className="absolutely-page">
      <div
        className="parallax short"
        style={{ backgroundImage: `url("${bannerImg}")` }}
      ></div>

      <div className="container">
        <div className="contentSpacing">
          <div className="big-title text-center text-uppercase m-y-30">
            ABSOLUTELY MCT FOR TODAY AND TOMORROW
          </div>
          <div className="paragraph text-center m-b-30">
            <div className="m-b-20">
              It is surprising that even today not many people know what
              medium-chain triglycerides (better known as MCT) are. MCTs are
              fatty acids (a type of fat) that are naturally found and extracted
              from coconut oil, palm kernel oil, and milk fat. It is common
              knowledge that fat is essential for overall health, and not all
              fats are created equal. Not all fats are bad for you.
            </div>
            <div className="m-b-20">
              Monolith Nutrition™ are specialists in the trade, production, and
              use of MCT across food and nutritional supplements. We order
              organic coconuts in bulk and our proprietary formulation
              techniques and experience coupled with nutritional knowledge and
              innovation give rise to some of the most unique MCT nutritional
              supplements in powder form, rather than the more common oil form.
            </div>
          </div>

          <div className="text-center">
            <div className="big-title text-uppercase m-b-30 fs-30">
              MONOLITH NUTRITION MCT DERIVED FROM 100% ORGANIC COCONUTS
            </div>
            <img
              className="coconutCutImg m-b-30"
              src={coconutCutImg}
              alt="cocounut"
            />
            <div className="paragraph">
              Ah... the humble coconut! So many uses and applications from
              consumption as food or refreshment, as alternative fuel, all
              across the value chain of agriculture and manufacturing, creation
              of beauty formulas, and of course used to extract MCT. Monolith
              Nutrition MCT products derive its C8 (caprylic acid) and C10
              (capric acid) from sustainably sourced organic coconuts.
            </div>
          </div>
        </div>
      </div>

      <div className="benefitsInfo p-relative text-center m-b-30">
        <div className="container">
          {coconutDetails.map((el, i, arr) => (
            <ContentDivider
              element={el}
              index={i}
              arr={arr}
              key={`${el.title}-${i}`}
            />
          ))}
          <Link
            to="/contact"
            className="btn btn-primary btn-large text-uppercase"
          >
            Shop for the best mct products
          </Link>
        </div>
      </div>

      {/* <div className="referenceContent m-b-50">
        <div className="container">
          <div className="big-title m-b-20 fs-30">REFERENCES</div>
          <div className="paragraph">
            1. Jadhav, H. B., & Annapure, U. S. (2023). Triglycerides of
            medium-chain fatty acids: a concise review. Journal of food science
            and technology, 60(8), 2143–2152.
            https://doi.org/10.1007/s13197-022-05499-w
          </div>
        </div>
      </div> */}

      <div className="beachSection p-relative">
        <div
          className="beachImg"
          // src={sustainImg}
          style={{ backgroundImage: `url("${coconutBanner}")` }}
          // alt="beach"
        ></div>
      </div>
    </div>
  );
}

export default AbsolutelyMct;
