import React from "react";
import { Link } from "react-router-dom";

// Antd
import { Breadcrumb } from "antd";

// Images
import bannerImg from "../assets/images/conditionOfSale.png";

// Breadcrumb Data
const breadcrumbItems = [
  { title: <Link to="/">Home</Link> },
  { title: <div>Conditions of Sale</div> },
];

function ConditionOfSale() {
  return (
    <div className="condition-page">
      <div className="container">
        <div className="breadcrumbContainer m-y-30">
          <Breadcrumb separator=">" items={breadcrumbItems} />
        </div>

        <div className="big-title text-uppercase text-center m-b-50">
          Conditions Of Sale
        </div>

        <div className="body m-b-50">
          <div className="font-bold m-b-20">
            No one loves to be sold to, but everybody loves to buy. We want you
            to have a great experience here that will go hand in hand with our
            great products!
          </div>
          <img className="m-b-20" src={bannerImg} alt="jogging" />
          <div className="paragraph">
            <div className="m-b-30">
              These Conditions of Sale apply to all purchases from and govern
              the sale of all products on MonolithNutrition.com. Please read
              these Conditions of Sale carefully before placing an order with
              MonolithNutrition.com. Each time you purchase from
              MonolithNutrition.com, you agree:
            </div>

            <ol className="m-b-30 p-l-30">
              <li className="m-b-30">
                To waive all rights to claim punitive, incidental, or
                consequential damages, injunctive relief, any other damages
                including legal fees, and any and all rights to have damages
                multiplied or otherwise increased. MONOLITHNUTRITION.COM SHALL
                NOT BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY IN
                ARBITRATION OR OTHERWISE FOR DIRECT, INDIRECT, INCIDENTAL,
                SPECIAL OR CONSEQUENTIAL DAMAGES INCLUDING LOST PROFITS,
                PERSONAL INJURY (INCLUDING DEATH) AND PROPERTY DAMAGE OF ANY
                NATURE WHATSOEVER. IN NO EVENT SHALL MONOLITHNUTRITION.COM'S
                TOTAL LIABILITY TO YOU FOR ALL DAMAGES AND LOSSES FOR ANY CAUSE
                OF ACTION EXCEED THE AMOUNT PAID BY YOU FOR YOUR PRODUCT(S).
                MONOLITHNUTRITION.COM DISCLAIMS, AND YOU WAIVE, ALL EXPRESS OR
                IMPLIED PRODUCT RELATED REPRESENTATIONS AND WARRANTIES,
                INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR
                NON-INFRINGEMENT. THIS DISCLAIMER DOES NOT APPLY TO MANUFACTURER
                PRODUCT WARRANTIES (IF ANY).
              </li>
              <li className="m-b-30">
                RETURNS: Please review to our Refund Policy which applies to all
                products sold on MonolithNutrition.com. MonolithNutrition.com
                may evaluate a customer’s account, order and return history, and
                has the right to refuse services and/or prevent a customer from
                placing future orders on MonolithNutrition.com.
              </li>
              <li className="m-b-30">
                CLEARANCE ITEMS: All clearance items are final sale and may not
                be returned. No price adjustments are applicable on the
                clearance items or other non-clearance items on current or prior
                sales.
              </li>
              <li className="m-b-30">
                PRODUCT INFORMATION: We are passionate about great products and
                solutions and this should be evident from being competent,
                accountable, and transparent with all product information
                displayed on MonolithNutrition.com. However, we understand that
                errors and different information both in terms of factual as
                well as expression of an actual product may differ from its
                packaging displayed on MonolithNutrition.com. All product
                information in this website should be used for information
                purposes only. Ingredients may also be subject change. DO NOT
                RELY SOLELY ON THE INFORMATION ON THIS WEBSITE. IT IS IMPORTANT
                TO ALWAYS READ PRODUCT LABELS, WARNINGS AND ADHERE TO THE
                DIRECTIONS ON THE PRODUCT LABEL BEFORE USE.
              </li>
              <li className="m-b-30">
                PRICING AND AVAILABILITY: We do our best to ensure product
                availability. Availability may be listed on individual product
                pages and we cannot be more specific beyond what is stated
                there. As an order is processed, we will inform you by email as
                soon as possible if any products you order turn out to be out of
                stock.
              </li>
              <li className="m-b-30">
                SHIPPING: We take great measures to ensure all products are
                professionally and carefully packed and shipped out swiftly.
                Please note that unless otherwise stated on the website,
                delivery estimates are just that, though we strive for the
                timely delivery. We cannot guarantee third party courier
                services and their delivery times, and therefore estimates
                should not be relied upon as such. In rare cases, products may
                be damaged during shipment by third party courier services, and
                we cannot be held liable for any items that are damaged during
                shipment. All orders are shipped on business days from Monday to
                Friday, excluding public holidays within Malaysia.
              </li>
              <li className="m-b-30">
                MISCELLANEOUS: We reserve the right to make changes to these
                Conditions of Sale at any time and without any notice. You will
                be subject to the Conditions of Sale in effect at the time of
                your purchase.
              </li>
            </ol>

            <div className="m-b-30">CORPORATE ADDRESS:</div>
            <div className="m-b-30">MONOLITH NUTRITION SDN BHD</div>
            <div className="m-b-30">
              No.F-3-G, 32 Avenue, Pusat Perniagaan Bukit Serdang, Jalan BS
              14/3,Taman Bukit Serdang, 43300 Seri Kembangan,Selangor
            </div>
            <div className="m-b-30">
              EMAIL US AT{" "}
              <a
                className="text-red"
                href="mailto:foundation@monolithnutrition.com"
              >
                foundation@monolithnutrition.com
              </a>
            </div>
            <div>
              Or GET IN TOUCH with us{" "}
              <Link className="text-red" to="/contact">
                here
              </Link>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConditionOfSale;
