import React, { Fragment, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authentications } from "../services/services";
import { login } from "../features/userSlice";
import { useGlobalState } from "../context/GlobalStateProvider";

// Constant Data
import { isPro } from "../configs/constantData";

// Antd
import { Flex, Form, message } from "antd";

// Components
import CustomInput from "../components/common/CustomInput";

// Ducks
import { registerUser } from "../ducks/services";

const formFields = [
  {
    label: "First name",
    name: "first_name",
    type: "text",
    required: true,
    reqMsg: "Please input your first name!",
  },
  {
    label: "Last name",
    name: "last_name",
    type: "text",
    required: false,
    reqMsg: "Please input your last name!",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    required: true,
    reqMsg: "Please input your email!",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    required: true,
    reqMsg: "Password is required!",
    // pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
  },
  {
    label: "Referral Code",
    name: "referral_code",
    type: "text",
    required: true,
    reqMsg: "Referral Code is required!",
    helperTxt: "If you don’t have a referral code, please enter MNA001",
  },
];

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { isLoggedIn } = useGlobalState();

  const [form] = Form.useForm();

  useEffect(() => {
    if (isLoggedIn()) navigate("/account", { replace: true });
  }, [isLoggedIn()]);

  useEffect(() => {
    if (searchParams.get("aff_id")) {
      localStorage.setItem("aff_id", searchParams.get("aff_id"));
      form.setFieldsValue({
        referral_code: searchParams.get("aff_id"),
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (localStorage.getItem("aff_id")) {
      form.setFieldsValue({
        referral_code: localStorage.getItem("aff_id"),
      });
    }
  }, [localStorage.getItem("aff_id")]);

  const performLogin = (response) => {
    let res = {
      access_token: response.data.meta.access_token, // frappe access token
      byebug_access_token: response.data.data.access_token, // byebug access token
      expires_in: response.data.data.expires_in,
      refresh_token: response.data.data.refresh_token,
    };

    if (res) {
      let data = response.data.data;
      localStorage.setItem("userid", JSON.stringify(data.id));
      localStorage.setItem("token", JSON.stringify(res));
      dispatch(
        login({
          username: data.email,
        })
      );
      setTimeout(() => {
        // props.setLoading(false);
        navigate("/");
      }, 1000);
    }
  };

  const onFinish = async (val) => {
    // return;
    // props.setLoading(true);
    let payload = { ...val, is_pro: isPro };

    await registerUser(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          message.success("Register Successful!");
          authentications(val.email, val.password)
            .then((response) => {
              performLogin(response);
            })
            .catch((error) => {
              // props.setLoading(false);
              message.error(error?.data?.message || `${error}`);
            });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      });
  };

  return (
    <div className="register-page">
      <div className="container">
        <div className="big-title text-center m-y-50 text-uppercase">
          Create account
        </div>
        <Form
          form={form}
          layout="vertical"
          name="register"
          style={{
            maxWidth: 600,
            margin: "0 auto",
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {formFields.map((el, i) => (
            <Fragment key={`form-input-${i}`}>
              <CustomInput item={el} />
            </Fragment>
          ))}

          <Form.Item>
            <Flex justify="space-between" align="center">
              <Link className="text-red fs-18 text-underline" to="/login">
                Login instead
              </Link>
              <button className="btn btn-primary btn-large" type="submit">
                Create my account
              </button>
              {/* After register auto login and redirect to home page */}
            </Flex>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Register;
