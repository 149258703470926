import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// API
import { getOption, getUserAddresses } from "../../ducks/actions";
import { deleteAddress } from "../../ducks/services";

// Antd
import { message, Modal, Spin } from "antd";

// Components
import AddressModal from "./AddressModal";

function MyAddresses(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [formMode, setFormMode] = useState(null);
  const [addressId, setAddressId] = useState(null);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.global.userData);
  const userAddresses = useSelector((state) => state.global.userAddresses);
  const checkoutDropdown = useSelector((state) => state.global.optionList);

  const country = checkoutDropdown?.countries?.find(
    (el) => el?.name === "Malaysia"
  );
  const countryDropdown = checkoutDropdown?.countries
    ?.filter((el) => el?.name === "Malaysia")
    ?.map((item) => ({ label: item?.name, value: item?.name }));

  const statesDropdown = country?.states?.map((el) => ({
    label: el,
    value: el,
  }));

  const showModal = (mode, id) => {
    if (mode === "edit") setAddressId(id);

    setFormMode(mode);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getOption());
    callAddressApi();
    // dispatch(getUserProfile());
  }, []);

  const callAddressApi = () => {
    dispatch(getUserAddresses(props.setLoading));
  };

  const handleDeleteAddress = async (id) => {
    props.setLoading(true);

    const payload = {
      address_id: id,
      status_event: "remove",
    };

    await deleteAddress(payload)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("Address removed successfully!");
          dispatch(getUserAddresses());
          setDeleteModal(false);
          setDeleteId(null);
        }
      })
      .catch((e) => {
        message.error(e.message ?? "Something went wrong");
      })
      .finally(() => {
        props.setLoading(false);
      });
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteId(null);
    setDeleteModal(false);
  };

  return (
    <div className="addressList m-b-50">
      <div className="addressListContainer">
        <div className="gridItem p-relative min-h-200">
          <div
            className="flex flex-col j-c-sb full-height cursor-pointer"
            onClick={() => showModal("add")}
          >
            <div>New Address</div>
            <div className="fs-30">+</div>
          </div>
        </div>
        {userAddresses?.list?.map((el, i) => (
          <div className="gridItem p-relative min-h-200" key={`address-${i}`}>
            <div className="flex flex-col j-c-sb gap-20 full-height">
              <div className="fs-20 font-bold">
                {el?.first_name} {el?.last_name}
              </div>

              <div>
                <div>{el?.address}</div>
                <div>{el?.address2}</div>
                <div>
                  {el?.city} {el?.state} {el?.postcode} {el?.country}
                </div>
                <div>{el?.phone_no}</div>
              </div>

              <div className="flex j-c-sb">
                <div className="flex gap-10">
                  <div
                    className="text-underline text-red cursor-pointer"
                    onClick={() => showModal("edit", el?.id)}
                  >
                    Edit
                  </div>
                  <div
                    className="text-underline text-red cursor-pointer"
                    onClick={() => handleOpenDeleteModal(el?.id)}
                  >
                    Remove
                  </div>
                </div>

                {el?.default_shipping_address === "1" && (
                  <div className="font-bold">Default Address</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <AddressModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        countryDropdown={countryDropdown}
        statesDropdown={statesDropdown}
        userData={userData}
        mode={formMode}
        addressId={addressId}
        userAddresses={userAddresses}
        reloadApi={callAddressApi}
        loading={props.loading}
        setLoading={props.setLoading}
      />

      <DeleteAddressModal
        openDeleteModal={openDeleteModal}
        deleteId={deleteId}
        onDeleteAddress={handleDeleteAddress}
        onCloseDeleteModal={handleCloseDeleteModal}
      />
    </div>
  );
}

function DeleteAddressModal({
  openDeleteModal,
  deleteId,
  onDeleteAddress,
  onCloseDeleteModal,
}) {
  return (
    <Modal
      open={openDeleteModal}
      title="Are you sure to remove the address?"
      footer={[
        <button
          className="btn btn-sub btn-large m-r-20"
          key="back"
          onClick={onCloseDeleteModal}
        >
          Return
        </button>,
        <button
          className="btn btn-primary btn-large"
          key="submit"
          onClick={() => onDeleteAddress(deleteId)}
        >
          Submit
        </button>,
      ]}
    ></Modal>
  );
}

export default MyAddresses;
