import React from "react";
import { Link, useNavigate } from "react-router-dom";

// Constant Data
import { isPro } from "../configs/constantData";

import { useGlobalState } from "../context/GlobalStateProvider";

// API
import { forgotPassword } from "../ducks/services";

// Antd
import { Flex, Form, Input, message } from "antd";

function ForgotPassword() {
  const { setLoading } = useGlobalState();
  const navigate = useNavigate();

  const onFinish = async (val) => {
    // return;
    setLoading(true);
    let payload = { ...val, is_pro: isPro };

    await forgotPassword(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          message.success(res.data.message);
          navigate("/login", { replace: true });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="forgot-pw-page">
      <div className="container">
        <div className="big-title text-center m-y-50">FORGOT PASSWORD</div>
        <Form
          layout="vertical"
          name="forgotPw"
          style={{
            maxWidth: 600,
            margin: "0 auto",
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item>
            <Flex justify="space-between" align="center">
              <Link className="active fs-18 text-underline" to="/login">
                Back to login
              </Link>
              <button className="btn btn-primary btn-large" type="submit">
                Send
              </button>
            </Flex>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;
