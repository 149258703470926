import React from "react";

// Antd
import { Input, Form, Radio, Select, Checkbox } from "antd";

function CustomInput({ item }) {
  return (
    <>
      {item?.type !== "checkbox" && item?.type !== "radio" && (
        <>
          <Form.Item
            style={item?.hidden ? { display: "none" } : {}}
            label={item?.label}
            name={item?.name}
            rules={[
              {
                required: item?.required,
                message: item?.reqMsg,
              },
              {
                pattern: item?.pattern,
                message: item?.patternMsg,
              },
            ]}
          >
            {item?.type === "text" && <Input placeholder={item?.placeholder} />}
            {item?.type === "email" && (
              <Input type="email" placeholder={item?.placeholder} />
            )}
            {item?.type === "password" && (
              <Input.Password placeholder={item?.placeholder} />
            )}
            {item?.type === "select" && (
              <Select
                className="full-width h-48"
                options={item?.options}
                size="large"
                placeholder={item?.placeholder}
                {...(item?.onChange && { onChange: item.onChange })}
              />
            )}

            {item?.type === "textarea" && (
              <Input.TextArea placeholder={item?.placeholder} rows={6} />
            )}
          </Form.Item>
          {item?.helperTxt && (
            <div className="paragraph fs-12" style={{ marginTop: "-20px" }}>
              {item?.helperTxt}
            </div>
          )}
        </>
      )}

      {item?.type === "checkbox" && (
        <Form.Item
          name={item?.name}
          valuePropName="checked"
          style={item?.hidden ? { display: "none" } : {}}
        >
          <Checkbox>{item.title}</Checkbox>
        </Form.Item>
      )}

      {item?.type === "radio" && (
        <Form.Item
          label={item?.label}
          rules={[
            {
              required: item?.required,
              message: item?.reqMsg,
            },
          ]}
        >
          <Radio.Group onChange={item?.onChange} value={item?.value}>
            {item?.radioOptions.map((el, i) => (
              <Radio value={el?.radioValue} key={`form-radio-${i}`}>
                {el?.radioLabel}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      )}
    </>
  );
}

export default CustomInput;
