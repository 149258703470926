import React from "react";

function PaymentForm({ formRef, paymentParams }) {
  return (
    <form
      ref={formRef}
      method="POST"
      name="ePayment"
      action="https://payment.ipay88.com.my/ePayment/entry.asp"
      style={{ display: "none" }}
    >
      <input name="MerchantCode" value={paymentParams.MerchantCode} />
      <input name="PaymentId" value={paymentParams.PaymentId} />
      <input name="RefNo" value={paymentParams.RefNo} />
      <input name="Amount" value={paymentParams.Amount} />
      <input name="Currency" value={paymentParams.Currency} />
      <input name="ProdDesc" value={paymentParams.ProdDesc} />
      <input name="UserName" value={paymentParams.UserName} />
      <input name="UserEmail" value={paymentParams.UserEmail} />
      <input name="UserContact" value={paymentParams.UserContact} />
      <input name="Remark" value={paymentParams.Remark} />
      <input name="Lang" value={paymentParams.Lang} />
      <input name="SignatureType" value={paymentParams.SignatureType} />
      <input name="Signature" value={paymentParams.Signature} />
      <input name="ResponseURL" value={paymentParams.ResponseURL} />
      <input name="BackendURL" value={paymentParams.BackendURL} />
      <button
        name="button"
        className="btn btn-lg btn-danger b-30 px-5"
        type="submit"
      >
        Pay Now
      </button>
    </form>
  );
}

export default PaymentForm;
