import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalState } from "../../context/GlobalStateProvider";

// API
import { getOrderHistory } from "../../ducks/actions";
import { submitPendingPayment } from "../../ducks/services";

// Antd
import { Table, Tag, Spin, message } from "antd";

// Components
import PaymentForm from "../Checkout/PaymentForm";

function MyOrders(props) {
  const dispatch = useDispatch();
  const orderHistoryList = useSelector((state) => state.global.orderHistory);
  const { setLoading } = useGlobalState();
  const formRef = useRef(null);
  const [paymentParams, setPaymentParams] = useState({});

  useEffect(() => {
    dispatch(getOrderHistory(props.setLoading));
  }, []);

  useEffect(() => {
    if (paymentParams && "Amount" in paymentParams) {
      // Submit the form programmatically
      formRef.current.submit();
    }
  }, [paymentParams]);

  const handlePendingPayment = async (val) => {
    setLoading(true);
    const payload = {
      uuid: val,
    };

    await submitPendingPayment(payload)
      .then((res) => {
        if (res.data.code === 200) {
          // message.success("Payment Successful!");
          setPaymentParams(res.data.data.payment_request);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableData = orderHistoryList?.list?.map((el, i) => ({
    key: i,
    product: (
      <div>
        <div className="m-b-10">
          <Tag color={el?.status === "Pending" ? "#faad14" : "#f5222d"}>
            {el?.status}
          </Tag>
          <div>Date: {el?.datetime ?? "-"}</div>
          <div>Reference: {el?.reference ?? "-"}</div>
        </div>
        {el?.sales_order_items?.map((item, index) => (
          <div
            className={`flex gap-10 a-i-center ${index === 0 ? "m-b-20" : ""}`}
            key={`salesOrderData-${index}`}
          >
            <img
              width={100}
              src={item?.product?.featured_graphic_url}
              alt={item?.product?.name}
            />
            <div className="flex flex-col">
              <div>Name: {item?.product?.name ?? "-"}</div>
              <div>Desc: {item?.product?.description ?? "-"}</div>
              <div>Quantity: {item?.quantity ?? "-"}</div>
              <div>Price: RM{item?.amount ?? "-"}</div>
            </div>
          </div>
        ))}
      </div>
    ),
    amount: (
      <div>
        <div>
          Amount: RM
          {el?.sales_order_items
            ?.reduce((acc, item) => acc + Number(item?.amount), 0)
            ?.toFixed(2)}
        </div>
        Shipping Fees: RM{el?.shipping_fees}
        <br />
        Total Amount: RM{el?.amount}
        {el?.status === "Pending" && (
          <>
            <br />
            <br />
            <button
              className="btn btn-primary btn-large"
              onClick={() => handlePendingPayment(el.uuid)}
            >
              Pay Now
            </button>
          </>
        )}
      </div>
    ),
  }));

  const tableHeader = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Price",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <div className="orderHistory m-b-50">
      <Table dataSource={tableData} columns={tableHeader} pagination={false} />
      {/* Payment form */}
      <PaymentForm formRef={formRef} paymentParams={paymentParams} />
    </div>
  );
}
export default MyOrders;
