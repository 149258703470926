import React from "react";
import { Link } from "react-router-dom";

// Antd
import { Breadcrumb } from "antd";

// Images
import bannerImg from "../assets/images/privacyPolicy.png";

// Breadcrumb Data
const breadcrumbItems = [
  { title: <Link to="/">Home</Link> },
  { title: <div>Privacy Policy</div> },
];

function PrivacyPolicy() {
  return (
    <div className="condition-page">
      <div className="container">
        <div className="breadcrumbContainer m-y-30">
          <Breadcrumb separator=">" items={breadcrumbItems} />
        </div>

        <div className="big-title text-uppercase text-center m-b-50">
          Privacy Policy
        </div>

        <div className="body m-b-50">
          <div className="font-bold m-b-20">
            Hello! In this day and age of information overload, we seek online
            privacy and security. We respect your privacy and here’s how we
            value and protect your data.
          </div>
          <img className="m-b-20" src={bannerImg} alt="jogging" />
          <div className="paragraph">
            <div className="m-b-30">
              If you are a customer, newsletter subscriber, and visitor of
              MonolithNutrition.com, this policy applies to you.
            </div>

            <ol className="m-b-30 p-l-30">
              <li className="m-b-30">
                We respect and are responsible in ensuring good, ethical, and
                legal use of data for your privacy in order to faciliate and
                ensure a positive, safe and engaging experience on
                MonolithNutrition.com.
                <br />
                <br />
                1.1 OUR RESPONSIBILITIES
                <br />
                If you are a customer, newsletter subscriber, and visitor of
                MonolithNutrition.com, we act as the "data controller" of
                personal data. This means we determine how and why your data are
                processed.
                <br />
                <br />
                1.1 YOUR RESPONSIBILITIES
                <br />
                Please do read this Privacy Policy to know your rights. If you
                provide us with personal information about other people, or if
                others give us your information, we will only use that
                information for the specific reason for which it was provided to
                us. By submitting all information, you confirm that you have the
                right to authorise us to process it on your behalf in accordance
                with this Privacy Policy.
              </li>
              <li className="m-b-30">
                We ask for and collect the following personal information about
                you when you use MonolithNutrition.com’s website, which is
                necessary to facilitate activities between you and us, for
                example, placing an order, subscribing to our newsletter,
                participating in sweepstakes, access to a rewards program, or to
                allow us to comply with various legal obligations. Without this
                information, we may not be able to provide you with all of the
                requested services.
                <br />
                <br />
                2.1. HOW WE USE THE INFORMATION YOU PROVIDE TO US
                <br />
                <br />
                • ACCOUNT INFORMATION:
                <br />
                When you sign up for a MonolithNutrition.com account, we require
                certain information such as your first and last/family name,
                email address, username, and password. You may also provide
                optional information as part of your MonolithNutrition.com
                profile, including additional contact information, location,
                etc.
                <br />
                <br />
                • ORDER INFORMATION: When you place an order on our website, we
                may request that you provide additional information such as your
                billing and shipping addresses and contact phone number.
                <br />
                <br />
                • PAYMENT INFORMATION: To facilitate processing of payments on
                our website, we require you to provide certain financial
                information, for example, your credit or debit card number.
                Payment card numbers are not stored by MonolithNutrition.com.
                <br />
                <br />
                • COMMUNICATIONS WITH MONOLITHNUTRITION.COM: When you
                communicate with MonolithNutrition.com via our website, email,
                or live chat, we collect information about your communication
                and any information you choose to provide.
                <br />
                <br />
                • ESSENTIAL COOKIES: Certain tags or cookies are necessary in
                order for MonolithNutrition.com’s website to operate correctly.
                These essential cookies are further described in our Cookies
                Policy.
                <br />
                <br />
                2.2. HOW WE USE THE INFORMATION WE GET FROM YOUR USE OF OUR
                WEBSITE AND SERVICES
                <br />
                When you use MonolithNutrition.com’s website, we automatically
                collect information, including personal information, about the
                services you use and how you use them, such as information about
                how you use or interact with our website, view or interact with
                our ads and content, and information about your computer or
                mobile device.This information may include:
                <br />
                <br />
                • USAGE INFORMATION: We collect information about your
                interactions with our website and mobile applications such as
                the pages or content you view, searched, and interacted with on
                MonolithNutrition.com’s website.
                <br />
                <br />
                • DEVICE INFORMATION: We automatically collect device
                information when you access our website, even if you have not
                created a MonolithNutrition.com account or logged in. That
                information includes, among other things: your IP address,
                operating system version, device type, system and performance
                information, and browser type. Additionally, certain services
                include a unique application number. This number and information
                about your installation may be sent to MonolithNutrition.com
                when you install or uninstall a certain service or when a
                service periodically contacts our servers, such as for updates.
                <br />
                <br />
                • LOG DATA: Like most websites today, MonolithNutrition.com’s
                server keeps log files that record data each time a device
                accesses those servers. The log files contain data about the
                nature of each access, including IP addresses, internet service
                providers, files viewed on our website, operating system
                versions, device type, and timestamps.
                <br />
                <br />
                • LOCAL STORAGE: Bodybuilding.com may collect and store
                information, including personal information, locally on your
                device using mechanisms such as browser web storage and
                application data caches.
                <br />
                <br />
                • LOCATION INFORMATION: When you use our services, we may
                collect information about your precise or approximate location
                as determined through data such as your IP address or mobile
                device’s GPS to offer you an improved user experience for things
                such as personalised content, language preferences, advertising,
                or search results. Most mobile devices allow you to control or
                disable the use of location services. Most likely, these
                controls are located in the device’s settings menu; however, if
                you have questions about how to disable your device’s location
                services, we recommend you contact your mobile service carrier
                or your device manufacturer. MonolithNutrition may also collect
                this information even when you are not using our apps if this
                connection is enabled through your settings or device
                permissions.
                <br />
                <br />
                • COOKIES AND SIMILAR TECHNOLOGIES: MonolithNutrition.com may
                set and access cookies on your computer. We may also set and
                access device identifiers which may include your IP address,
                user agent information (e.g., browser version, OS type, and
                version), and device-provided identifiers. MonolithNutrition.com
                also lets other trusted businesses – such as payment service
                providers – use cookies or similar technologies for advertising,
                analytics, and research purposes on some of
                MonolithNutrition.com’s website.
                <br />
                <br />• PAYMENT TRANSACTION INFORMATION: MonolithNutrition.com,
                including its payment service provider, collects information
                related to your payment transactions through our website or
                mobile applications, including the payment instrument used, date
                and time, payment amount, payment instrument expiration date,
                and billing post code. This information is essential for
                facilitating orders and purchases between you and
                MonolithNutrition.com for provision of payment services.
              </li>
              <li className="m-b-30">
                WHEN AND HOW WE COLLECT DATA
                <br />
                If you are only a visitor to our website, please know that we do
                not collect any personal data before you request a demo of
                Bearer. We use the privacy-friendly analytics Plausible to carry
                out site measurement absolutely anonymously. From the moment you
                request a demo of Bearer, we start collecting data. Sometimes
                you provide us with data, sometimes data about you is collected
                automatically. Here’s when and how we do this: Here at
                MonolithNutrition.com, we store and process information in order
                to provide, improve, and develop MonolithNutrition.com’s website
                and services through:
                <br />
                <br />
                • Enabling you to access and use this website.
                <br />
                • Operating, protecting, improving, and optimising
                MonolithNutrition.com’s web services and experience, such as by
                performing analytics and conducting research, part of which may
                include contacting you via the contact information you have
                provided to us.
                <br />
                • Offering you a personalised experience by, among other things,
                ranking search results or showing customised ads based on your
                searches, interests, and preferences.
                <br />
                • Contacting you via contact information you have provided to us
                regarding order information, product information, shipping
                status, subscription status, or other MonolithNutrition.com
                account related questions or support.
                <br />
                • Enabling you to access and use our relating online payment
                services to place orders or sign up for subscriptions.
                <br />
                • Providing customer service via convenient avenues such as
                website live chat, chat messenger, email, or phone.
                <br />
                • Sending you service or support messages.
                <br />
                <br />
                To provide a personalised experience and improve our advertising
                and marketing We hate spam and prefer to receive information and
                advertising that actually are of interest to us. Information we
                collect helps us to provide a customised and targeted
                user-experience such as:
                <br />
                <br />
                • Sending promotional messages, marketing, advertising, and
                other information of interest. There will be an option for you
                to opt out of receiving these communications at any time by
                clicking on the “unsubscribe” link in relating emails.
                <br />
                • Personalising, measuring, and improving advertising to be more
                tailored to your interests in our products, reading and
                information content found in this website.
                <br />
                • Administering rewards, contests, sweepstakes, referral
                programs, surveys, or other promotional activities or events
                sponsored or managed by MonolithNutrition.com.
                <br />
                <br />
                We do not share data with third party business entities. We are
                not in the business of selling our customers’ data or personal
                information to others. Additionally, we impose strict
                restrictions on how our partners can use and disclose the data
                we provide. We have summarized how we share the information we
                collect below.
              </li>
              <li className="m-b-30">
                COMPLIANCE WITH LAWS, RESPONDING TO LEGAL REQUESTS, PREVENTING
                HARM, AND PROTECTION OF RIGHTS
                <br />
                MonolithNutrition.com may disclose your information, including
                personal information, to courts, law enforcement, or
                governmental authorities, or authorised third parties, if and to
                the extent we are required or permitted to do so by law or if
                such disclosure is reasonably necessary to: (i) comply with our
                legal obligations; (ii) comply with legal process and to respond
                to claims or demands asserted against MonolithNutrition.com;
                (iii) respond to verified requests relating to a criminal
                investigation or alleged or suspected illegal activity or any
                other activity that may expose us, you, or any other of our
                users to legal liability; (iv) to enforce and administer our
                Terms of Use or other agreements; (v) protect the rights,
                property, or personal safety of Monolith Nutrition, its
                employees, or members of the public.
              </li>
              <li className="m-b-30">
                SERVICE AND TECHNOLOGY PROVIDERS
                <br />
                MonolithNutrition.com uses a variety of third-party service
                providers to help us provide services related to the
                MonolithNutrition’s website and our services. Service providers
                may be located inside or outside of Malaysia. For example,
                services providers may help us: (i) conduct fraud prevention and
                risk assessments; (ii) perform product development, maintenance,
                and debugging; (iii) provide customer service, advertising,
                payment, and transactional services; (iv) help us track website
                analytics, such as conversion and traffic metrics; and (v)
                verify information against public databases.
                MonolithNutrition.com may need to share your information,
                including personal information, in order to ensure the adequate
                performance of our contract with you (e.g., name and billing
                address to complete a transaction and ship your order). We enter
                into confidentiality and data processing terms with third-party
                service providers to ensure they comply with high levels of
                confidentiality and best practices in privacy and security
                standards.
              </li>
              <li className="m-b-30">
                SECURITY
                <br />
                We are continuously implementing and updating robust
                administrative, technical, and physical security measures to
                help protect your data and information against unauthorised
                access, loss, destruction, or alteration. Some of the safeguards
                we use to protect your information include firewalls and data
                encryption, compliance certifications, information access
                control, and thorough security and breach response policies and
                procedures. In the event of any actual or suspected unauthorised
                use of your MonolithNutrition.com account, please contact us. We
                are always ready to assist.
              </li>
              <li className="m-b-30">
                SAFETY OF MINORS
                <br />
                Parental supervision on the Internet is important and the safety
                of minors is paramount. Use of MonolithNutrition.com’s website
                is not intended for and may not be used by individuals under the
                age of 13. All purchases by minors should be made on a valid
                parent or guardian account and within adult supervision.
                MonolithNutrition.com does not knowingly collect personal data
                from minors or allow them to register accounts. If it comes to
                our attention that we have collected personal data from a minor,
                we may delete this information without notice. If you have
                reason to believe that this has occurred, please contact us for
                assistance.
              </li>
              <li className="m-b-30">
                YOUR RIGHT TO KNOW AND ACCESS YOUR PERSONAL INFORMATION
                <br />
                At any time, you may submit a verifiable request for information
                regarding the: (a) categories of your personal data we collect
                and use; (b) purposes for which categories of personal data are
                collected or used by MonolithNutrition.com; (c) categories of
                sources from which we collect personal data; and (d) specific
                pieces of personal data we have collected about you.
                <br />
                <br />
                8.1. RIGHT TO EQUAL SERVICE
                <br />
                MonolithNutrition.com is against discrimination and everyone may
                exercise their privacy rights.
                <br />
                <br />
                8.2. RIGHT TO DELETE YOUR PERSONAL INFORMATION
                <br />
                You may submit a verifiable request to close your account here
                at MonolithNutrition.com and we will delete personal data about
                you that we have collected.
                <br />
                <br />
                8.3. RIGHT TO REQUEST THAT YOUR PERSONAL INFORMATION NOT BE SOLD
                <br />
                We reiterate our commitment to customer privacy and that
                MonolithNutrition.com is not in the business of selling personal
                data of our users or customers.
              </li>
              <li className="m-b-30">
                CHANGES TO THIS PRIVACY POLICY
                <br />
                MonolithNutrition.com reserves the right to modify this Privacy
                Policy at any time in accordance with this provision. If we make
                changes to this Privacy Policy, we will post the revised Privacy
                Policy on the MonolithNutrition.com website. If its changes are
                significant, we may also provide you with notice of the
                modification by email or get your consent, as required by law.
                If you disagree with the revised Privacy Policy, you may cancel
                your MonolithNutrition.com Account. If you do not cancel your
                account, your continued access or use of the
                MonolithNutrition.com website will constitute acceptance of a
                revised Privacy Policy.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
