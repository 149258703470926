import React from "react";

function CustomAccordion({ data, num, curOpen, onCurOpen }) {
  const isOpen = curOpen === num;
  const contentStyle = {
    maxHeight: isOpen ? "1000px" : "90px",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  };

  const handleClick = () => {
    onCurOpen(isOpen ? null : num);
  };

  return (
    <div
      className={`accordionBox ${isOpen ? "opened" : ""}`}
      style={contentStyle}
      onClick={handleClick}
    >
      <div className="boxTitle font-bold fs-20 text-uppercase">
        {data.title}
        <div className="expandIcon"></div>
      </div>
      <div className="boxContent">{data.content}</div>
    </div>
  );
}

export default CustomAccordion;
