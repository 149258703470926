import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalState } from "../context/GlobalStateProvider";
import { useNavigate, useSearchParams } from "react-router-dom";

// Constant Data
import { isPro } from "../configs/constantData";

// Components
import CustomInput from "../components/common/CustomInput";

// API
import { submitFreeSampleForm } from "../ducks/services";
import { login } from "../features/userSlice";

// Antd
import { Form, Row, Col, Input, Select, message } from "antd";

// Scoped CSS
import styles from "./FreeSample.module.scss";

// Images
import freeSampleImg from "../assets/images/free-sample.png";

function FreeSample() {
  const { setLoading } = useGlobalState();
  const dispatch = useDispatch();
  const [gender, setGender] = useState("male");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const product_id = searchParams.get("pid");

  const formFields = useMemo(() => {
    return [
      {
        label: "Name",
        name: "name",
        type: "text",
        required: true,
        reqMsg: "Please insert name!",
        placeholder: "Please insert name",
      },
      {
        label: "Email",
        name: "email",
        type: "email",
        required: true,
        reqMsg: "Please insert email!",
        placeholder: "Please insert email",
      },
      {
        label: "Contact Number",
        name: "phone",
        type: "text",
        required: true,
        reqMsg: "Please insert contact number!",
        placeholder: "Please insert contact number",
      },
      {
        label: "Gender",
        name: "gender",
        type: "radio",
        required: true,
        reqMsg: "Required",
        radioOptions: [
          { radioLabel: "Male", radioValue: "male" },
          { radioLabel: "Female", radioValue: "female" },
        ],
        value: gender,
        onChange: (e) => {
          setGender(e.target.value);
        },
      },
    ];
  }, [gender]);

  const onFinish = async (val) => {
    setLoading(true);

    const payload = { ...val, gender, product_id };

    await submitFreeSampleForm(payload)
      .then((res) => {
        if (res.data.code === 200) {
          form.resetFields();
          navigate(`/guest-checkout?pid=${product_id}&cid=${res.data.data}`);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.contactPage}>
      <div className={styles.freeSampleBannerContainer}>
        <img className={styles.banner} src={freeSampleImg} alt="banner" />
      </div>
      <div className="container">
        <div className="big-title text-center text-uppercase m-y-50">
          Get In Touch!
        </div>

        <div className="paragraph text-center m-b-50">
          There’s never been a better time and reason to take charge, get
          involved, and tap into the opportunity of becoming a Monolith
          Nutrition Lifestyle Affiliate! Our quality products are some of the
          best tasting and effective foundational nutrition supplements that
          resonate and relate to everyday people who prioritise a holistic
          lifestyle of health and wellness. You are about to be rewarded in the
          business of improving other people’s health and lives through quality,
          affordable, honest, and enduring products.
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={`${styles.contactForm} m-b-100`}
        >
          {formFields.map((el, i) => (
            <Fragment key={`contact-form-${i}`}>
              <CustomInput item={el} />
            </Fragment>
          ))}

          <button
            className="btn btn-primary btn-large full-width"
            type="submit"
          >
            Submit
          </button>
        </Form>
      </div>
    </div>
  );
}

export default FreeSample;
