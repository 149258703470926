import React from "react";

function QtyEditor({ qty, minusQty, addQty, changeQty, min }) {
  return (
    <div className="itemQuantity flex a-i-center">
      <div className="qtyBtn" onClick={minusQty}>
        -
      </div>
      <input
        type="number"
        className="qtyDisplay"
        value={qty}
        onChange={(e) => changeQty(e.target.value)}
        min={min}
      />
      <div className="qtyBtn" onClick={addQty}>
        +
      </div>
    </div>
  );
}

export default QtyEditor;
