import React, { useState } from "react";
import { Link } from "react-router-dom";

// Constant Data
import { isPro } from "../../configs/constantData";

// API
import { submitContactForm } from "../../ducks/services";

// Antd
import { message } from "antd";

// Scoped CSS
import styles from "./Footer.module.scss";

// Images
import logo from "../../assets/images/logo.png";

const otherLinks = [
  // { name: "FAQ", link: "/faq" },
  { name: "Conditions of Sale", link: "/condition-of-sale" },
  { name: "Privacy Policy", link: "/privacy-policy" },
  { name: "Refund Policy", link: "/return-policy" },
];

function Footer() {
  const [email, setEmail] = useState("");

  const onFinish = async (e) => {
    e.preventDefault();
    if (email === "") {
      return;
    }

    let payload = {
      email: email,
      is_pro: isPro,
      contact_type: "subscriber",
    };

    await submitContactForm(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          message.success("Subscribe newsletter successfully!");
          setEmail("");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      });
  };
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.row}>
          <div className="col">
            <div className={styles.subscribeCol}>
              <img className={styles.logo} src={logo} alt="logo" />
              {/* <div className={styles.subscription}> */}
              <div className="title text-red font-bold fs-20">
                SUBSCRIBE FOR BETTER LIVING
              </div>
              <div className="body paragraph">
                More than just special offers, giveaways, and value deals – live
                life by learning from health and lifestyle insights, articles,
                tips, and more. You get stuff in your inbox that actually brings
                value in your life! (No Spam, we promise.)
              </div>

              <div className={styles.subscribeForm}>
                <form className="form" onSubmit={onFinish}>
                  <input
                    type="email"
                    name="sub-email"
                    id="sub-email"
                    placeholder="Enter your email address"
                    style={{ color: "#fff" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button className="text-red" type="submit">
                    SUBMIT
                  </button>
                </form>
              </div>
              {/* </div> */}
            </div>
          </div>

          <div className="col">
            <div className={styles.otherLinkCol}>
              {/* <div className="title font-bold fs-20">OTHER LINKS</div> */}
              {/* <div className="links"> */}
              {otherLinks.map((el, i) => (
                <Link to={el.link} key={`other-link-${i}`}>
                  {el.name}
                </Link>
              ))}
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <div className="paragraph m-b-30">
            <span className="text-red">Disclaimer</span>: Statements made on
            this website and its respective products sold through this website
            is not meant to be complete, exhaustive, or to be applicable to any
            specific individual’s medical condition. The products and solutions
            presented in this website is not to be used for diagnosing,
            treating, curing, mitigating, or preventing any type of disease or
            medical condition. Always consult a licensed and qualified doctor or
            health care professional before making any decisions relating to
            natural, integrative or conventional health and treatment regimens.
          </div>
          <div className="paragraph">
            © Copyright 2024 Monolith Nutrition | All rights reserved. Monolith
            Nutrition is a registered trademark of Monolith Nutrition Sdn Bhd.
            (Co. reg. 654474-V)
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
