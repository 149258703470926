import React from "react";

// Scoped CSS
import styles from "../../pages/WhyMonolith.module.scss";

function ProductBox({ item }) {
  return (
    <div className={styles.productBox}>
      <img src={item.img} alt={item.name} />
      {item.element}
    </div>
  );
}

export default ProductBox;
