import React from "react";

// Img
import freesampleImg from "../../assets/images/free-sample.png";

function CheckoutItems({ data }) {
  return (
    <div className="flex j-c-sb a-i-center">
      <div className="productItem flex gap-20">
        <div className="productItemImg p-relative">
          <img
            src={
              data?.product?.featured_graphic_url
                ? data?.product?.featured_graphic_url
                : freesampleImg
            }
            alt={data?.product?.name}
          />

          <div className="itemQty">{data?.qty}</div>
        </div>
        <div className="productItemName">
          <div className="title m-b-10">
            {data?.product?.name} - {data?.weight}g
          </div>
          <div className="desc paragraph fs-14">
            {data?.product?.description}
          </div>
        </div>
      </div>

      <div className="productPrice">RM{data?.amount}</div>
    </div>
  );
}

export default CheckoutItems;
